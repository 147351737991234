import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { AuthGuard } from './authentication/guards/auth.guard';
import { LoginComponent } from './authentication/login/login.component';
import { NotfoundComponent } from './authentication/404/not-found.component';
import { RegisterComponent } from './authentication/register/register.component';
import { TrabajoConsentimientoPublicoComponent } from './trabajos/trabajo-consentimiento-publico/trabajo-consentimiento-publico.component';
import { PauseComponent } from './authentication/pause/pause.component';

const routes: Routes = [
  {
      path: '',
      component: FullComponent,
      canActivate: [AuthGuard],
      children: [
          { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
          { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
          { path: 'configuracion', loadChildren: () => import('./configuracion/configuracion.module').then(m => m.ConfiguracionModule) },
          { path: 'comunicaciones', loadChildren: () => import('./comunicaciones/comunicaciones.module').then(m => m.ComunicacionesModule) },
          { path: 'notificaciones', loadChildren: () => import('./notificaciones/notificaciones.module').then(m => m.NotificacionesModule) },
          { path: 'estadisticas', loadChildren: () => import('./estadisticas/estadisticas.module').then(m => m.EstadisticasModule) },
          { path: 'estudios', loadChildren: () => import('./estudios/estudios.module').then(m => m.EstudiosModule) },
          { path: 'managers', loadChildren: () => import('./managers/managers.module').then(m => m.ManagersModule) },
          { path: 'tarifas', loadChildren: () => import('./tarifas/tarifas.module').then(m => m.TarifasModule) },
          { path: 'artistas', loadChildren: () => import('./artistas/artistas.module').then(m => m.ArtistasModule) },
          { path: 'clientes', loadChildren: () => import('./clientes/clientes.module').then(m => m.ClientesModule) },
          { path: 'trabajos', loadChildren: () => import('./trabajos/trabajos.module').then(m => m.TrabajosModule) },
          { path: 'blank', loadChildren: () => import('./blank/blank.module').then(m => m.BlankModule) },
          { path: 'denegado', loadChildren: () => import('./denegado/denegado.module').then(m => m.DenegadoModule) },
          { path: 'navegacion', loadChildren: () => import('./movilnav/movilnav.module').then(m => m.MovilnavModule) },
          { path: 'recuento', loadChildren: () => import('./recuento/recuento.module').then(m => m.RecuentoModule) },
          { path: 'recuentorango', loadChildren: () => import('./recuentorango/recuentorango.module').then(m => m.RecuentoRangoModule) },
          { path: 'liquidacion', loadChildren: () => import('./liquidacion/liquidacion.module').then(m => m.LiquidacionModule) },
          { path: 'pago', loadChildren: () => import('./pago/pago.module').then(m => m.PagoModule) },
          { path: 'ayuda', loadChildren: () => import('./ayuda/ayuda.module').then(m => m.AyudaModule) },
          { path: 'registrocambios', loadChildren: () => import('./registrocambios/registrocambios.module').then(m => m.RegistrocambiosModule) },
          { path: 'trabajoexpress', loadChildren: () => import('./trabajoexpress/trabajoexpress.module').then(m => m.TrabajoexpressModule) }
      ]
  }, 
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'pause',
    component: PauseComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'consentimiento-publico/:idCliente/:idTrabajo',
    component: TrabajoConsentimientoPublicoComponent,
  },
  { 
    path: '**',
    component: NotfoundComponent
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }