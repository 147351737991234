
<div id="layout-wrapper">
 
   <app-navigation></app-navigation>
    
    <div class="main-content">

        <div class="page-content">
            <div class="container-fluid pt-0 pt-md-0 pt-lg-5">

                <router-outlet></router-outlet>
                
            </div> 
        </div>

        
       
    </div>
    <footer class="footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6">
                    <a href="https://g.page/r/CdX-es3EtautEB0/review" class="text-white" target="_blank">Ayúdanos con tu reseña en <i class="bx bxl-google align-middle font-size-20"></i></a>
                </div>
                <div class="col-sm-6">
                    <div class="text-sm-right d-none d-sm-block">
                        © Inkoru V1.0 - Gestión de Estudios de Tatuaje
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>