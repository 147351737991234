export class GamaCromatica
{
    constructor() {}

    static get_list()
    {
        return [
            { id : 1,  nombre : 'Negro' },
            { id : 2,  nombre : 'Color' },
            
        ];
            
    }

    static get_texto( valor : number )
    {
        const listado = this.get_list();
        let rtn_value = '';
        listado.forEach( item => {
            if ( item.id == valor) 
            {
                rtn_value = item.nombre;
            }
        });

        return rtn_value;
    }
}