<div>
    <div class="p-0">
        <div class="row no-gutters">
            
            <div class="d-none d-md-block col-md-7 col-lg-8 col-xl-9">
                <div class="auth-full-bg bg-soft-dark">
                    <div class="w-100">
                        <div class="bg-overlay"></div>
                        <div class="vh-100 d-flex align-items-end">
                            <div class="w-100 pb-5">
                                <div class="row justify-content-center">
                                    <div class="col-12">
                                        <div class="text-center text-light">
                                            
                                            <h5 class="mb-3 text-white">Los grandes ya trabajan con Inkoru</h5>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end col -->

            <div class="col-12 col-md-5 col-lg-4 col-xl-3">
                <div class="auth-full-page-content p-md-5 p-4 bg-light">
                    <div class="w-100">

                        <div class="d-flex flex-column h-100 text-center">
                            <div class="mb-4 mb-md-5">
                                <a href="index.html" class="d-block">
                                    <img src="assets/images/logo-dark.png" alt="" height="40" class="auth-logo-dark">
                                </a>
                            </div>
                            <div class="mt-4 p-5">
                                <img src="assets/images/sesion-pausada.jpg" class="img-fluid">
                            </div>
                            <div class="mt-4">
                                <form class="form-horizontal" (submit)="desbloquear(formPause.value)" [formGroup]="formPause" >

                                    <div class="user-thumb text-center mb-4">
                                        <img class="rounded-circle img-thumbnail avatar-md" src="{{ global_url + user!.avatar }}" alt="{{ user!.name }}">
    
                                        <h5 class="font-size-12 mt-3">{{ user?.name }}, has pausado tu sesión. Vuelve a escribir tu contraseña para acceder al panel.</h5>
                                    </div>
                                    <div class="form-group">
                                        <input type="password" class="form-control text-center" id="userpassword" placeholder="Escribe tu contraseña" formControlName="password">
                                    </div>
        
                                    <div class="form-group row mb-0">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-dark w-md waves-effect waves-light" type="submit" [disabled]="formPause.invalid">Entrar</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="mt-5 text-center">
                                <p>¿No eres tú? Vuelva a <a (click)="logout()" class="font-weight-medium text-dark"> logearte </a> </p>
                                <p>© Inkoru  - Tattoo Management</p>
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </div>
    <!-- end container-fluid -->
</div>