import { ChangeDetectorRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApicallsService } from '../services/apicalls.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../services/authentication.service';
import * as global from '../services/global.service';
import * as moment from 'moment';
import { Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-cliente-listado',
  templateUrl: './cliente-listado.component.html',
  styleUrls: ['./cliente-listado.component.scss']
})
export class ClienteListadoComponent implements OnInit {
    @Output() clienteSelected = new EventEmitter<number>();
    isDarkMode = false;
    loader: boolean = true;

    clientes: any;
    idColegio: any;
    clientesFilter: any[] = [];
    deleteCliente: any;
    selectedCliente: any;
    selectedClienteCopy: any;
    editable = false;
    page = 1;
    pageSize = 10;
    fileName= 'Clientes.xlsx';  
    collectionSize = 0;
    _searchTerm = '';
    global_url: any;
    
    trabajos:any;
    paises: any[];
    paisesCargados: boolean = false;
    loading: boolean;

    roleinfo:any;
    idMember:any;
    
    clienteSeleccionado:any;

    public imagePath: string | null = null;
    imgURL: string | null = null;
    public message = '';
    user: any;
    constructor(
      private router: Router,
      private toastr: ToastrService,
      private api: ApicallsService,
      private auth: AuthenticationService,
      private modalService: NgbModal,
      public activeModal: NgbActiveModal,
      private cdRef: ChangeDetectorRef,
      private currentroute: ActivatedRoute) {
    }

    ngOnInit() {
        const storedTheme = localStorage.getItem('theme');
        if (storedTheme === 'dark') {
            this.isDarkMode = true;
            document.body.classList.add('dark-mode');
        }
        this.user = this.auth.currentUserValue;
        this.getClientes();
        this.global_url = global.API_URL;

        this.cargarPaises();
        this.getTrabajos();

        if(this.user.rol_id == 3){
          this.api.getRoleInfo(this.user.id).subscribe((data: any) => {
            this.roleinfo = data.success.user;
            this.idMember = data.success.user.id;
          });
        }
    }

    getTrabajos(){
      this.trabajos = null;
      this.api.getTrabajosEstudioNew(this.user.estudio_id, 1).subscribe( ( data: any ) => {
          this.loader = false;
          this.loading = false;
          this.trabajos = data.response;
      });
  
    }
    
    get searchTerm(): string {
      return this._searchTerm;
    }
    set searchTerm(val: string) {
      this._searchTerm = val;
      this.clientesFilter = this.filter(val);
      this.page = 1;
    }
    filter(v: string) {
      return this.clientes.filter((x: { nombre: string; apellido1: string; email: string; telefono: string; }) => {
        let result: string = x.apellido1 ? x.nombre + ' ' + x.apellido1 : x.nombre;
        let email: string = x.email ? x.email : '';
        let telefono: string = x.telefono ? x.telefono : '';
    
        let nombre: string = v.split(' ')[0];
        let apellido1: string = v.split(' ')[1];
        let busqueda: string = apellido1 ? nombre + ' ' + apellido1 : nombre;
    
        // Normaliza las cadenas para quitar las tildes y acentos antes de realizar la comparación
        let resultNormalized = result.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        let busquedaNormalized = busqueda.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    
        return resultNormalized.indexOf(busquedaNormalized) !== -1 || email.toLowerCase().indexOf(v.toLowerCase()) !== -1 || telefono.indexOf(v) !== -1;
      });
    }
    
    invertirOrden() {
      this.clientesFilter = [...this.clientesFilter].reverse();
    }


    parseDate(fecha){
      return moment(fecha, 'YYYY/MM/DD').format('DD/MM/YYYY');
    }

    editElement(Cliente: any) {
        this.router.navigate(['/Clientes/edit/'+Cliente.id]);
    }
    changePageSize(items: any){
      this.pageSize = items;
    }
    getClientes(){
        this.api.getClientesEstudioNew(this.user.estudio_id).subscribe( ( data: any ) => {
            this.clientes = data.response;
            this.clientesFilter = this.clientes;
            this.collectionSize = this.clientesFilter.length;
            this.loader = false;
        });
    }
    
    selectCliente(clienteId: number) {
      this.clienteSelected.emit(clienteId);
      this.activeModal.close();
    }  


    cargarPaises() {
      this.api.getAllPaises().subscribe((datacat: any) => {
        this.paises = datacat.response;
        this.paisesCargados = true;
      });
    }
    

}
