import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApicallsService } from '../../services/apicalls.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit {

  formRegister!: UntypedFormGroup;
  pasError = false; 
  levelPass = 0;
  sendPin= false;
  formNewPassword!: UntypedFormGroup;
  newPassword = false;
  loading = false;
  submitted = false;
  idUser: any;
  returnUrl!: string;
  userUpdate: any;
  wizard: any;
  constructor(private formBuilder: UntypedFormBuilder,
              private route: ActivatedRoute,
              private toastr: ToastrService,
              private api: ApicallsService,
              private router: Router,
              private authenticationService: AuthenticationService) {

              if (this.authenticationService.currentUserValue) {
                  this.router.navigate(['/dashboard']);
              }
  }
  registerform = true;
  recoverform = false;
  
  ngOnInit() {
    this.formRegister = this.formBuilder.group({
      name: ['', [ Validators.required]],
      email: ['', [ Validators.required, Validators.email]],
      password: ['', Validators.required],
      password_confirm: ['', Validators.required],
      promo: ['']
    });
 

    
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/login';
  }

 
  showRecoverForm() {
    this.registerform = !this.registerform;
    this.recoverform = !this.recoverform;
  }

  register(formValue: any) {
    if(formValue.password == formValue.password_confirm){
      this.api.checkUserMail(formValue.email).subscribe( (data: any) => {
        if(data.users == 0){
          this.api.newUser(formValue).subscribe( data => {
            this.toastr.success('Usuario registrado correctamente', 'Nuevo estudio');
            this.router.navigate(['/login']);
          }, error => {
            this.toastr.error('No se ha podido registrar el usuario', 'Nuevo estudio');
          }
        );
        }else{
          this.toastr.error('Ya existe un usuario con el email indicado', 'Crear nuevo estudio');
        }
      });
      
    }else{
      this.toastr.error('Las contraseñas no coinciden', 'Nuevo estudio');
    }
   
  }


}
