<div class="modal-header">
     <h4 class="modal-title">Confirmar los cambios</h4>
     <button type="button" class="close" aria-label="Close" (click)="onCancelar()">
       <span aria-hidden="true">&times;</span>
     </button>
   </div>
   <div class="modal-body pb-0">
      <p class="text-dark font-weight-bold">{{ mensaje }}</p>
      <div class="custom-control custom-checkbox custom-checkbox-primary mb-1">
        <input class="custom-control-input" type="checkbox" id="avisarArtista" [(ngModel)]="opcionesNotificacion.avisarArtista">
        <label class="custom-control-label" for="avisarArtista">
        Avisar artista
        </label>
      </div>
      <div class="custom-control custom-checkbox custom-checkbox-primary mb-1">
        <input class="custom-control-input" type="checkbox" id="avisarCliente" [(ngModel)]="opcionesNotificacion.avisarCliente">
        <label class="custom-control-label" for="avisarCliente">
        Avisar cliente
        </label>
      </div>
      <div class="custom-control custom-checkbox custom-checkbox-primary mb-1">
        <input class="custom-control-input" type="checkbox" id="avisarEstudio" [(ngModel)]="opcionesNotificacion.avisarEstudio">
        <label class="custom-control-label" for="avisarEstudio">
        Avisar estudio
        </label>
      </div>
    </div>
   <div class="modal-footer">
      <div class="btn-group btn-group-example mb-3 w-100" role="group">
          <button type="button" class="btn btn-primary w-xs" (click)="onConfirmar()">
              <i class="mdi mdi-thumb-up"></i> Guardar
          </button>
          <button type="button" class="btn btn-danger w-xs" (click)="onCancelar()">
              <i class="bx bx-block align-middle"></i> No guardar
          </button>
      </div>
   </div>
   