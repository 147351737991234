import { Component } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'inkoru-front';
  constructor( private router: Router) {

  }
  ngOnInit() {
    var pause = localStorage.getItem('pause');
    console.log(location.hostname);
    if (location.hostname != 'localhost' && location.protocol === 'http:') {
      window.location.href = location.href.replace('http', 'https');
    }else{
      if(pause == '1'){
        this.router.navigate(['/pause']);
      }
    }
    
  }
}
