     <div class="modal-header text-center">
          <h4 class="modal-title">
          {{ mostrandoFormularioTrabajo ? 'Crear nuevo trabajo' : mostrandoFormularioAusencia ? 'Crear ausencia de artista' : mostrandoFormulario ? 'Crear nuevo evento' : 'Selecciona una opción' }}
          </h4>
          <button type="button" class="close" aria-label="Close" (click)="cancelar()">
          <span aria-hidden="true">&times;</span>
          </button>
     </div>
   
     <div class="modal-body pb-0">
          <ng-container *ngIf="!mostrandoFormularioTrabajo && !mostrandoFormulario && !mostrandoFormularioAusencia">
          <ul class="list-group">
               <li *ngFor="let opcion of opciones" class="list-group-item list-group-item-action" (click)="seleccionarOpcion(opcion)">
                    {{ opcion }}
               </li>
          </ul>
          </ng-container>

          <ng-container *ngIf="mostrandoFormularioTrabajo">
               <!-- Formulario de trabajo -->
               <form>
                    <!-- Selección o creación de cliente -->
                    <div *ngIf="!trabajoData.cliente_nuevo">
                    <!-- Botón para seleccionar cliente existente -->
                         <button type="button" class="btn btn-primary btn-block mb-3" (click)="selectCliente($event.target)" *ngIf="!selectedCliente && !trabajoData.cliente_nuevo">
                              <i class="mdi mdi-account-search font-size-20 align-middle"></i> Seleccionar cliente
                         </button>
                         <div *ngIf="selectedCliente && !trabajoData.cliente_nuevo" class="mb-2 bg-soft-primary p-2 text-dark rounded">
                              <strong><i class="bx bx-user-circle"></i> Cliente seleccionado:</strong> {{ selectedCliente.nombre }} {{ selectedCliente.apellido1 }} {{ selectedCliente.apellido2 }}
                              <span (click)="clearSelectedCliente()" class="badge badge-pill badge-primary ml-1 cursor-pointer">Cambiar cliente</span>
                         </div>

                    </div>
                    <div class="form-group custom-control custom-switch custom-switch-md  mb-3" dir="ltr" [class.d-none]="selectedCliente">
                         <input type="checkbox" class="custom-control-input" [(ngModel)]="trabajoData.cliente_nuevo" name="cliente_nuevo" id="cliente_nuevo" [disabled]="selectedCliente">
                         <label class="custom-control-label align-middle" for="cliente_nuevo">Crear cliente nuevo</label>
                    </div>
                    <div *ngIf="trabajoData.cliente_nuevo" class="row">
                         <!-- Campos para crear cliente nuevo -->
                         <div class="col-md-6">
                              <div class="form-group">
                                   <label for="nombreCliente" class="et-nombre text-dark font-size-10 bg-soft-secondary font-weight-bold">Nombre <span class="text-danger">*</span></label>
                                   <input type="text" id="nombreCliente" class="form-control" [(ngModel)]="trabajoData.cliente.nombre" name="nombreCliente" required />
                              </div>
                         </div>
                         <div class="col-md-6">
                              <div class="form-group">
                                   <label for="apellido1" class="et-nombre text-dark font-size-10 bg-soft-secondary font-weight-bold">Primer apellido</label>
                                   <input type="text" id="apellido1" class="form-control" [(ngModel)]="trabajoData.cliente.apellido1" name="apellido1" />
                              </div>
                         </div>
                         <div class="col-md-6">
                              <div class="form-group">
                                   <label for="telefono" class="et-nombre text-dark font-size-10 bg-soft-secondary font-weight-bold">Teléfono</label>
                                   <input type="text" id="telefono" class="form-control" [(ngModel)]="trabajoData.cliente.telefono" name="telefono" (change)="onTelefonoEmailChange()"/>
                              </div>
                         </div>
                         <div class="col-md-6">
                              <div class="form-group">
                                   <label for="email" class="et-nombre text-dark font-size-10 bg-soft-secondary font-weight-bold">Email</label>
                                   <input type="text" id="email" class="form-control" [(ngModel)]="trabajoData.cliente.email" name="email" (change)="onTelefonoEmailChange()"/>
                              </div>
                         </div>

                         <!-- Botón para verificar si el cliente ya existe -->
                         <div class="col-md-12" *ngIf="mostrarBotonVerificarCliente">
                              <div class="form-group text-center">
                              <button type="button" class="btn btn-block"
                              [ngClass]="{
                                   'btn-primary': estadoVerificacionCliente === 'no-verificado',
                                   'btn-secondary': estadoVerificacionCliente === 'verificando',
                                   'btn-success': estadoVerificacionCliente === 'sin-coincidencias',
                                   'btn-warning': estadoVerificacionCliente === 'con-coincidencias'
                              }"
                              (click)="verificarClienteExistente()"
                              [disabled]="estadoVerificacionCliente === 'verificando' || estadoVerificacionCliente === 'sin-coincidencias' || estadoVerificacionCliente === 'con-coincidencias'">
                         
                              <i *ngIf="estadoVerificacionCliente === 'verificando'" class="bx bx-cog bx-spin"></i>
                              <span *ngIf="estadoVerificacionCliente === 'no-verificado'"><i class="mdi mdi-account-search align-middle font-size-18 mr-1"></i> Verificar si el cliente ya existe</span>
                              <span *ngIf="estadoVerificacionCliente === 'verificando'">Verificando...</span>
                              <span *ngIf="estadoVerificacionCliente === 'sin-coincidencias'"><i class="mdi mdi-account-check align-middle font-size-18 mr-1"></i> No se han encontrado clientes coincidentes</span>
                              <span *ngIf="estadoVerificacionCliente === 'con-coincidencias'"><i class="mdi mdi-account-alert align-middle font-size-18 mr-1"></i> Se han encontrado clientes coincidentes</span>
                              </button>
                              </div>
                         </div>
                         <!-- Mostrar clientes coincidentes -->
                         <div class="col-md-12" *ngIf="estadoVerificacionCliente === 'con-coincidencias'">
                              <div class="alert alert-warning">
                              <p class="font-weight-bold text-dark">Se han encontrado {{ clientesCoincidentes.length }} coincidencia(s):</p>
                              <ul class="list-unstyled mb-0">
                              <li *ngFor="let cliente of clientesCoincidentes" class="mb-2">
                                   <strong><i class="mdi mdi-account-circle align-middle mr-1"></i> {{ cliente.nombre }} {{ cliente.apellido1 }}</strong>
                                   <button type="button" class="btn btn-sm btn-primary ml-1" (click)="asignarClienteExistente(cliente)">Asignar a esta cita</button>
                              </li>
                              </ul>
                              </div>
                         </div>

                         <div class="col-md-12">
                              <div class="form-group custom-control custom-switch custom-switch-sm  mb-3" dir="ltr">
                                   <input type="checkbox" class="custom-control-input" [(ngModel)]="trabajoData.cliente.es_ingles" name="es_ingles" id="es_ingles">
                                   <label class="custom-control-label align-middle" for="es_ingles">Cliente de habla inglesa</label>
                              </div>
                         </div>
   
                    </div>

                    <!-- Activar señal -->
                    <div class="row">
                         <div class="col-md-12">
                              <div class="form-group custom-control custom-switch custom-switch-sm  mb-3" dir="ltr">
                                   <input type="checkbox" class="custom-control-input" [(ngModel)]="trabajoData.has_senal" name="has_senal" id="has_senal">
                                   <label class="custom-control-label align-middle" for="has_senal">El cliente deja señal</label>
                              </div>
                         </div>
                    </div>
                    <div class="row" *ngIf="trabajoData.has_senal">
                         <div class="col-md-6">
                              <div class="form-group">
                                   <label for="senal" class="et-nombre text-dark font-size-10 bg-soft-secondary font-weight-bold">Señal pagada <span class="text-danger">*</span></label>
                                   <input type="number" id="senal" class="form-control" [(ngModel)]="trabajoData.senal" name="senal" min="0" required />
                              </div>
                         </div>
                         <div class="col-md-6">
                              <div class="form-group">
                              <label class="control-label et-nombre text-dark font-size-10 bg-soft-secondary font-weight-bold" for="forma_pagosenal">Forma pago señal:</label>
                              <select class="form-control select2" id="forma_pagosenal" [(ngModel)]="trabajoData.forma_pagosenal" name="forma_pagosenal">
                                   <option></option>
                                   <option value="efectivo">Efectivo</option>
                                   <option value="tarjeta">Tarjeta</option>
                                   <option value="transferencia">Transferencia</option>
                                   <option value="bizum">Bizum</option>
                                   <option value="paypal">Paypal</option>
                                   <option value="yappy">Yappy</option>
                              </select>
                              </div>
                         </div>
                         <div class="col-md-6">
                              <div class="form-group">
                              <label for="fecha_pagosenal" class="et-nombre text-dark font-size-10 bg-soft-secondary font-weight-bold">Fecha pago señal</label>
                              <input class="form-control" type="date" id="fecha_pagosenal" value="dd/mm/yyyy" [(ngModel)]="trabajoData.fecha_pagosenal" name="fecha_pagosenal">
                              </div>
                         </div>
                         <div class="col-md-6">
                              <div class="form-group">
                              <label class="control-label et-nombre text-dark font-size-10 bg-soft-secondary font-weight-bold" for="recoge_pagosenal">¿Quién la recibe?</label>
                              <select class="form-control select2" id="recoge_pagosenal" [(ngModel)]="trabajoData.recoge_pagosenal" name="recoge_pagosenal">
                                        <option></option>
                                        <option value="1" id="estudio" selected>El estudio</option>
                                        <option value="2" id="artista">El artista</option>
                              </select>
                              </div>
                         </div>
                    </div>

                    <hr class="mt-2">
               
                    <div class="row">
                         <div class="col-md-12" *ngIf="cabinas && cabinas.length > 0">
                              <div class="form-group">
                              <label for="cabina" class="et-nombre text-dark font-size-10 bg-soft-secondary font-weight-bold">Cabina <span class="text-danger">*</span></label>
                              <select id="cabina" class="form-control" [(ngModel)]="trabajoData.cabina_id" (change)="onCabinaChange()" name="cabina" required>
                                   <option value="">Selecciona la cabina</option>
                                   <option *ngFor="let cabina of cabinas" [value]="cabina.id" [selected]="trabajoData.cabina_id === cabina.id">
                                        {{ cabina.nombre }}
                                   </option>
                              </select>
                              </div>
                         </div>
                         <div class="col-md-12" *ngIf="ubicaciones && ubicaciones.length > 0">
                              <div class="form-group">
                              <label for="ubicacion" class="et-nombre text-dark font-size-10 bg-soft-secondary font-weight-bold">Ubicación <span class="text-danger">*</span></label>
                              <select id="ubicacion" class="form-control" [(ngModel)]="trabajoData.ubicacion_id" name="ubicacion" required>
                                   <option value="">Selecciona la ubicación</option>
                                   <option *ngFor="let ubicacion of ubicaciones" [value]="ubicacion.id" [selected]="trabajoData.ubicacion_id === ubicacion.id">
                                        {{ ubicacion.nombre }}
                                   </option>
                              </select>
                              </div>
                         </div>
                         <!-- Tipo preyecto -->
                         <div class="col-md-12">
                              <div class="form-group">
                              <label class="et-nombre text-dark font-size-10 bg-soft-secondary font-weight-bold">Tipo de trabajo <span class="text-danger">*</span></label>
                              <select class="form-control" [(ngModel)]="trabajoData.tipo_proyecto" name="tipo_proyecto" (change)="onTipoTrabajoChange()" required>
                              <option [ngValue]="null">Selecciona</option>
                              <option *ngFor="let tipo of tiposTrabajo" [ngValue]="tipo.value">
                                   {{ tipo.label }}
                              </option>
                              </select>
                              </div>
                         </div>
                         <!-- Mostrar campo "Cambio de Piercing" si corresponde -->
                         <div class="col-md-12" *ngIf="trabajoData.tipo_proyecto == 3">
                              <div class="form-group">
                                   <div class="custom-control custom-switch mb-2" dir="ltr">
                                        <input type="checkbox" class="custom-control-input" [(ngModel)]="trabajoData.cambio_piercing" id="cambio_piercing" name="cambio_piercing">
                                        <label class="custom-control-label" for="cambio_piercing">
                                             Se trata de un <strong>cambio de Piercing</strong>.
                                        </label>
                                   </div>
                              </div>
                         </div>
                         <!-- Mostrar campo "Revisión de Piercing" si corresponde -->
                         <div class="col-md-12" *ngIf="trabajoData.tipo_proyecto == 3">
                              <div class="form-group">
                                   <div class="custom-control custom-switch mb-3" dir="ltr">
                                        <input type="checkbox" class="custom-control-input" [(ngModel)]="trabajoData.revision" id="revision" name="revision">
                                        <label class="custom-control-label" for="revision">
                                             Es una <strong>revisión de Piercing</strong>.
                                        </label>
                                   </div>
                              </div>
                         </div>
                         <!-- Selector de "Fase de la cita" -->
                         <div class="col-md-12" *ngIf="fasesCita.length > 0">
                              <div class="form-group">
                              <label class="et-nombre text-dark font-size-10 bg-soft-secondary font-weight-bold">Fase de la cita <span class="text-danger">*</span></label>
                              <select class="form-control" [(ngModel)]="trabajoData.fase_cita" name="fase_cita" required>
                              <option [ngValue]="null">Selecciona</option>
                              <option *ngFor="let fase of fasesCita" [ngValue]="fase.value">
                                   {{ fase.label }}
                              </option>
                              </select>
                              </div>
                         </div>
                    </div>

                    <hr class="mt-2">

                    <div class="row">
                         <div class="col-md-6" *ngIf="!esArtistaLogueado">
                              <div class="form-group">
                                   <label for="artista" class="et-nombre text-dark font-size-10 bg-soft-secondary font-weight-bold">Artista <span class="text-danger">*</span></label>
                                   <select id="artista" class="form-control" [(ngModel)]="trabajoData.artista_id" name="artista" required (change)="artistaSeleccionado($event.target.value)">
                                        <option value="">Selecciona al artista</option>
                                        <option *ngFor="let artista of artistas" [value]="artista.id">
                                        {{ artista.nombre }} {{ artista.apellido1 }}
                                        </option>
                                   </select>
                              </div>
                         </div>
                         <div [ngClass]="esArtistaLogueado ? 'col-md-12' : 'col-md-6'">
                              <div class="form-group">
                                   <label for="comision_artista" class="et-nombre text-dark font-size-10 bg-soft-secondary font-weight-bold">Comisión artista <span class="text-danger">*</span></label>
                                   <input type="number" id="comision_artista" class="form-control" [(ngModel)]="trabajoData.comision_artista" name="comision_artista" required [readonly]="!puedeCambiarComision"/>
                              </div>
                         </div>
                    </div>

                    <hr class="mt-2">

                    <!-- Fecha y Hora de Inicio -->
                    <div class="row">
                         <div class="col-md-6">
                         <div class="form-group">
                         <label for="fecha" class="et-nombre text-dark font-size-10 bg-soft-secondary font-weight-bold">Fecha <span class="text-danger">*</span></label>
                         <input type="date" id="fecha" class="form-control" [(ngModel)]="trabajoData.fecha" name="fecha" (change)="onFechaChange()" required />
                         </div>
                         </div>
               
                         <div class="col-md-6">
                         <div class="form-group">
                         <label for="horai" class="et-nombre text-dark font-size-10 bg-soft-secondary font-weight-bold">Hora de inicio <span class="text-danger">*</span></label>
                         <input type="time" id="horai" class="form-control" [(ngModel)]="trabajoData.horai" (change)="onHoraiChange(); calcularHoraFin()" name="horai" required />
                         </div>
                         </div>
                    </div>
               
                    <!-- Duración -->
                    <div class="row">
                         <div class="col-md-6">
                              <div class="form-group">
                                  <label for="duracion" class="et-nombre text-dark font-size-10 bg-soft-secondary font-weight-bold">
                                      Duración <span class="text-danger">*</span>
                                  </label>
                                  <select id="duracion" class="form-control" [(ngModel)]="trabajoData.duracion" (change)="onDuracionChange()" name="duracion" required>
                                        <option [ngValue]="15">Quince minutos</option>
                                        <option [ngValue]="30">Media hora</option>
                                        <option [ngValue]="60">Una hora</option>
                                        <option [ngValue]="90">Hora y media</option>
                                        <option [ngValue]="120">Dos horas</option>
                                        <option [ngValue]="150">Dos horas y media</option>
                                        <option [ngValue]="180">Tres horas</option>
                                        <option [ngValue]="210">Tres horas y media</option>
                                        <option [ngValue]="240">Cuatro horas</option>
                                        <option [ngValue]="270">Cuatro horas y media</option>
                                        <option [ngValue]="300">Cinco horas</option>
                                        <option [ngValue]="330">Cinco horas y media</option>
                                        <option [ngValue]="360">Seis horas</option>
                                        <option [ngValue]="390">Seis horas y media</option>
                                        <option [ngValue]="420">Siete horas</option>
                                        <option [ngValue]="450">Siete horas y media</option>
                                        <option [ngValue]="480">Ocho horas</option>
                                        <option [ngValue]="510">Ocho horas y media</option>
                                        <option [ngValue]="540">Nueve horas</option>
                                        <option [ngValue]="570">Nueve horas y media</option>
                                        <option [ngValue]="600">Diez horas</option>
                                        <option [ngValue]="630">Diez horas y media</option>
                                        <option [ngValue]="660">Once horas</option>
                                        <option [ngValue]="690">Once horas y media</option>
                                        <option [ngValue]="720">Doce horas</option>
                                  </select>
                              </div>
                          </div>
                          
               
                         <!-- Hora de fin calculada dinámicamente -->
                         <div class="col-md-6">
                         <div class="form-group">
                         <label class="et-nombre text-dark font-size-10 bg-soft-secondary font-weight-bold">Hora de fin</label>
                         <input type="text" class="form-control" [value]="trabajoData.horaf" disabled />
                         </div>
                         </div>
                    </div>

                    

                    <!-- Precio -->
                    <div class="row">
                         <div class="col-md-12">
                              <div class="form-group">
                                   <label for="precio" class="et-nombre text-dark font-size-10 bg-soft-secondary font-weight-bold">Precio</label>
                                   <input type="number" id="precio" class="form-control" [(ngModel)]="trabajoData.precio" name="precio" min="0" />
                              </div>
                         </div>
                    </div>
                    <!-- Descripción -->
                    <div class="row">
                         <div class="col-md-12">
                              <div class="form-group">
                                   <label for="descripcion" class="et-nombre text-dark font-size-10 bg-soft-secondary font-weight-bold">Descripción</label>
                                   <textarea id="descripcion" class="form-control" [(ngModel)]="trabajoData.descripcion" name="descripcion" rows="2" placeholder="Descripción del trabajo"></textarea>
                              </div>
                         </div>
                    </div>
                    <!-- Enviar email al cliente -->
                    <div class="row">
                         <div class="col-md-12">
                              <div class="form-group custom-control custom-switch custom-switch-sm  mb-3" dir="ltr">
                                   <input type="checkbox" class="custom-control-input" [(ngModel)]="trabajoData.enviar_info_cliente" name="enviar_info_cliente" id="enviar_info_cliente">
                                   <label class="custom-control-label align-middle" for="enviar_info_cliente">Mandar email al cliente con la info de la cita</label>
                              </div>
                         </div>
                    </div>
                    
                    <!-- Verificar solapamiento -->
                    <div class="row" *ngIf="trabajoData.cabina_id && trabajoData.fecha && trabajoData.duracion && (trabajoData.horaf > trabajoData.horai)">
                         <div class="col-md-12">
                              <!-- Botón para verificar solapamiento -->
                              <div class="form-group mt-0">
                                   <button type="button" class="btn btn-block"
                                   [ngClass]="{
                                   'btn-primary': estadoVerificacion === 'no-verificado',
                                   'btn-secondary': estadoVerificacion === 'verificando',
                                   'btn-success': estadoVerificacion === 'sin-solapamiento',
                                   'btn-danger': estadoVerificacion === 'con-solapamiento'
                                   }"
                                   (click)="verificarSolapamiento()"
                                   [disabled]="estadoVerificacion === 'verificando' || estadoVerificacion === 'sin-solapamiento' || estadoVerificacion === 'con-solapamiento'">
                              
                                   <i *ngIf="estadoVerificacion === 'verificando'" class="bx  bx-loader-circle bx-spin"></i>
                                   <span *ngIf="estadoVerificacion === 'no-verificado'"><i class="mdi mdi-table-merge-cells font-size-18 align-middle"></i> Verificar si hay solapamiento entre citas/cabinas</span>
                                   <span *ngIf="estadoVerificacion === 'verificando'">Verificando...</span>
                                   <span *ngIf="estadoVerificacion === 'sin-solapamiento'"><i class="mdi mdi-check-outline font-size-18 align-middle"></i> No existe solapamiento con otras citas</span>
                                   <span *ngIf="estadoVerificacion === 'con-solapamiento'"><i class="mdi mdi-alert-circle font-size-18 align-middle"></i> Existen citas en el día, horas y cabina seleccionados</span>
                                   </button>
                              </div>
                         </div>
                    </div>
     
               </form>
          </ng-container>

          <ng-container *ngIf="mostrandoFormularioAusencia">
               <!-- Formulario de ausencia -->
               <form>
                    <div class="form-group" *ngIf="!esArtistaLogueado">
                         <label for="artista" class="et-nombre text-dark font-size-10 bg-soft-secondary font-weight-bold">Artista</label>
                         <select id="artista" class="form-control" [(ngModel)]="ausenciaData.artista_id" name="artista_id">
                              <option *ngFor="let artista of artistas" [value]="artista.id">{{ artista.nombre }} {{ artista.apellido1 }}</option>
                         </select>
                    </div>
                    <!-- Si el usuario es un artista, no mostrar el selector de artistas -->
                    <div class="alert alert-success" role="alert" *ngIf="esArtistaLogueado">
                         Los días seleccionados como <strong>ausente</strong> se añadirán al calendario del estudio. Podrás editarlos o eliminarlos fácilmente desde tu perfil.
                    </div>

                    <!-- Checkbox para Ausencia por horas -->
                    <div *ngIf="mostrarCheckboxAusenciaPorHoras" class="form-group custom-control custom-switch mb-3" dir="ltr">
                         <input type="checkbox" class="custom-control-input" id="ausenciaPorHoras" [(ngModel)]="ausenciaData.ausencia_por_horas" name="ausencia_por_horas">
                         <label class="custom-control-label" for="ausenciaPorHoras">Ausencia por horas</label>
                    </div>

                    <!-- Campos de hora inicio y fin, visibles solo si el checkbox está marcado -->
                    <div *ngIf="ausenciaData.ausencia_por_horas" class="row">
                         <div class="col-md-6">
                         <div class="form-group">
                              <label for="hora_inicio" class="et-nombre text-dark font-size-10 bg-soft-secondary font-weight-bold">Hora de inicio</label>
                              <input type="time" id="hora_inicio" class="form-control" [(ngModel)]="ausenciaData.hora_inicio" name="hora_inicio">
                         </div>
                         </div>
                         <div class="col-md-6">
                         <div class="form-group">
                              <label for="hora_fin" class="et-nombre text-dark font-size-10 bg-soft-secondary font-weight-bold">Hora de fin</label>
                              <input type="time" id="hora_fin" class="form-control" [(ngModel)]="ausenciaData.hora_fin" name="hora_fin">
                         </div>
                         </div>
                    </div>
               </form>
          </ng-container>
     
          <ng-container *ngIf="mostrandoFormulario">
               <form>
                    <div class="form-group" *ngIf="!esArtistaLogueado">
                         <label for="artista" class="et-nombre text-dark font-size-10 bg-soft-secondary font-weight-bold">Artista</label>
                         <select id="artista" class="form-control" [(ngModel)]="eventoData.artista_id" name="artista_id">
                         <option *ngFor="let artista of artistas" [value]="artista.id">{{ artista.nombre }} {{ artista.apellido1 }}</option>
                         </select>
                    </div>
                    <!-- Si el usuario es un artista, no mostrar el selector de artistas -->
                    <div class="alert alert-success" role="alert" *ngIf="esArtistaLogueado">
                         Se creará un evento con el rango de fechas seleccionadas, asociado a tu perfil de artista.
                    </div>
                    <div class="form-group">
                         <label for="descripcion" class="et-nombre text-dark font-size-10 bg-soft-secondary font-weight-bold">Descripción</label>
                         <textarea id="descripcion" class="form-control" [(ngModel)]="eventoData.descripcion" name="descripcion"></textarea>
                    </div>
               </form>
          </ng-container>
     </div>
   
     <div class="modal-footer pt-0">
          <div class="btn-group btn-group-example mb-3 w-100" role="group">
               <button *ngIf="mostrandoFormularioTrabajo" type="button" class="btn btn-primary w-xs" (click)="guardarTrabajo()">
                    <i class="mdi mdi-thumb-up"></i> Guardar
               </button>
               <button *ngIf="mostrandoFormularioAusencia" type="button" class="btn btn-primary w-xs" (click)="guardarAusencia()">
                    <i class="mdi mdi-thumb-up"></i> Guardar
               </button>
               <button *ngIf="mostrandoFormulario" type="button" class="btn btn-primary w-xs" (click)="guardarEvento()">
                    <i class="mdi mdi-thumb-up"></i> Guardar
               </button>
               <button type="button" class="btn btn-danger w-xs" (click)="cancelar()">
                    <i class="bx bx-block align-middle"></i> {{ (mostrandoFormularioTrabajo || mostrandoFormulario || mostrandoFormularioAusencia) ? 'No guardar' : 'Cerrar' }}
               </button>
          </div>
     </div>