export class Estilo
{
    constructor() {}

    static get_list()
    {
        return [
            { id : 1,  nombre : 'Abstracto' },
            { id : 51,  nombre : 'Anime' },
            { id : 2,  nombre : 'Biomecanico' },
            { id : 3,  nombre : 'Black' },
            { id : 57,  nombre : 'Blackout' },
            { id : 50,  nombre : 'Black and grey' },
            { id : 4,  nombre : 'Blackwork' },
            { id : 59,  nombre : 'Blast Over' },
            { id : 5,  nombre : 'Brush' },
            { id : 6,  nombre : 'Cartoon' },
            { id : 7,  nombre : 'Celta' },
            { id : 8,  nombre : 'Chicano' },
            { id : 9,  nombre : 'Comic' },
            { id : 10,  nombre : 'Cover' },
            { id : 11,  nombre : 'Cubism' },
            { id : 56,  nombre : 'Dark' },
            { id : 12,  nombre : 'Design' },
            { id : 13,  nombre : 'Dolls' },
            { id : 14,  nombre : 'Dotwork' },
            { id : 15,  nombre : 'Expresionismo' },
            { id : 16,  nombre : 'Floral' },
            { id : 17,  nombre : 'Freehand' },
            { id : 18,  nombre : 'Geometrico' },
            { id : 54,  nombre : 'Gótico' },
            { id : 19,  nombre : 'Handpoke' },
            { id : 20,  nombre : 'Ilustración' },
            { id : 21,  nombre : 'Japonés' },
            { id : 22,  nombre : 'Kawaii' },
            { id : 23,  nombre : 'Lettering' },
            { id : 24,  nombre : 'Linework' },
            { id : 25,  nombre : 'Línea' },
            { id : 26,  nombre : 'Magical' },
            { id : 53,  nombre : 'Mandala' },
            { id : 49,  nombre : 'Manga' },
            { id : 55,  nombre : 'Maorí' },
            { id : 27,  nombre : 'Maxdotwork' },
            { id : 28,  nombre : 'Microilustración' },
            { id : 29,  nombre : 'Microrealismo' },
            { id : 30,  nombre : 'Minimalismo' },
            { id : 31,  nombre : 'Monocromático' },
            { id : 32,  nombre : 'Neotradicional' },
            { id : 33,  nombre : 'New School' },
            { id : 34,  nombre : 'Offset' },
            { id : 35,  nombre : 'Old School' },
            { id : 36,  nombre : 'Oriental' },
            { id : 37,  nombre : 'Ornamental' },
            { id : 38,  nombre : 'Particionad' },
            { id : 39,  nombre : 'Pixel' },
            { id : 60,  nombre : 'Polinesio' },
            { id : 40,  nombre : 'Puntos' },
            { id : 41,  nombre : 'Realismo' },
            { id : 58,  nombre : 'Realismo color' },
            { id : 42,  nombre : 'Sketch' },
            { id : 43,  nombre : 'Stencil' },
            { id : 52,  nombre : 'Surrealismo' },
            { id : 44,  nombre : 'Tradicional' },
            { id : 45,  nombre : 'Trash' },
            { id : 46,  nombre : 'Trashpolka' },
            { id : 47,  nombre : 'Tribal' },
            { id : 48,  nombre : 'Watercolor' },

        ];
            
    }

    static get_texto( valor : number )
    {
        const listado = this.get_list();
        let rtn_value = '';
        listado.forEach( item => {
            if ( item.id == valor) 
            {
                rtn_value = item.nombre;
            }
        });

        return rtn_value;
    }
}