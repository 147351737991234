import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApicallsService } from '../../services/apicalls.service';
import { ToastrService } from 'ngx-toastr';
import { ClienteListadoComponent } from '../../cliente-listado/cliente-listado.component';
import * as moment from 'moment';

//Para el formulario de nuevo trabajo
//Controlar los tipos de proyecto y fase cita
interface OpcionSelect {
  value: number;
  label: string;
  tipoProyecto?: number[];
}

@Component({
  selector: 'app-modal-calendario',
  templateUrl: './modal-calendario.component.html',
  styleUrls: ['./modal-calendario.component.scss']
})
export class ModalCalendarioComponent {
  @Input() opciones: string[];
  @Input() selectionInfo: any;
  @Input() estudio_id: string;
  @Input() estudio: any; //datos del estudio
  @Input() user: any; // Recibimos el usuario logueado
  @Input() permisosArtista: any; // Recibimos los permisos del artista
  
  opcionSeleccionada: string;
  
  // Especificaciones y permisos si el usuario logueado es un artista
  esArtistaLogueado: boolean = false;
  puedeCambiarComision: boolean = false;

  // Datos del evento
  mostrandoFormulario: boolean = false;
  artistas: any[];
  eventoData = {
    artista_id: null,
    descripcion: ''
  };
  
  // Datos de las ausencias
  mostrandoFormularioAusencia: boolean = false;
  mostrarCheckboxAusenciaPorHoras: boolean = false;
  ausenciaData = {
    artista_id: null,
    ausencia_por_horas: false,
    hora_inicio: null,
    hora_fin: null     
  };

  //Tipos proyecto y fase cita
  tiposTrabajo: OpcionSelect[] = [];
  fasesCita: OpcionSelect[] = [];
  todasLasFases: OpcionSelect[] = [];
  
  // Para verificar si el cliente existe
  mostrarBotonVerificarCliente: boolean = false;
  estadoVerificacionCliente: 'no-verificado' | 'verificando' | 'sin-coincidencias' | 'con-coincidencias' = 'no-verificado';
  clientesCoincidentes: any[] = [];

  // Para verificar solapamientos de cabina
  verificacionRealizada: boolean = false;
  solapamiento: boolean = false;
  estadoVerificacion: 'no-verificado' | 'verificando' | 'sin-solapamiento' | 'con-solapamiento' = 'no-verificado';

  // Datos de los trabajos
  mostrandoFormularioTrabajo: boolean = false;
  selectedCliente: any = null;
  cabinas: any = null;
  ubicaciones: any = null;
  clientes: any[] = [];
  trabajoData: any = {
    cliente_id: null,
    cliente_nuevo: false,
    cliente: {
      nombre: '',
      apellido1: '',
      telefono: '',
      email: '',
      es_ingles: false
    },
    cabina_id: null,
    tipo_proyecto: null,
    fase_cita: null,
    cambio_piercing: false,
    revision: false,
    artista_id: null,
    comision_artista: null,
    precio: null,
    fecha: null,
    horai: null,
    duracion: null,
    horaf: null,
    descripcion: '',
    has_senal: false,
    senal: null,
    forma_pagosenal: '',
    fecha_pagosenal: null,
    recoge_pagosenal: 1,
    enviar_info_cliente: true,
    express: '5',
  };


  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private api: ApicallsService,
    private cdRef: ChangeDetectorRef,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    // Si el usuario logueado es un artista no cargamos el listado de artistas
    // ya que no debe seleccionar a un artista para crear trabajos, eventos o ausencias
    if (this.user && this.user.rol_id === 4) {
      this.esArtistaLogueado = true;
      this.trabajoData.artista_id = this.permisosArtista.id;
      this.trabajoData.comision_artista = this.permisosArtista.porcentaje_comision;
      this.ausenciaData.artista_id = this.permisosArtista.id;
      this.eventoData.artista_id = this.permisosArtista.id;
      this.puedeCambiarComision = this.permisosArtista ? this.permisosArtista.cambiar_comision === 1 : false;
    } else {
      // Si no es un artista, ahora sí, carga a todos los artistas
      this.puedeCambiarComision = true;
      this.cargarArtistas();
    }
    this.api.getClientesEstudio(this.estudio_id).subscribe((data: any) => {
      this.clientes = data.clientes;
    });
    this.api.getCabinasEstudio(this.estudio_id).subscribe( ( data: any ) => {
        this.cabinas = data.response;
    });
    this.api.getUbicacionesEstudio(this.estudio_id).subscribe( ( data: any ) => {
        this.ubicaciones = data.response;
    });
    console.log('Info selección:', this.selectionInfo);
    

    // Inicializar tipos de trabajo
    this.tiposTrabajo = [
      { value: 1, label: 'Cita previa' },
      { value: 2, label: 'Walk-in' },
      { value: 3, label: 'Piercing' },
      { value: 4, label: 'Láser' },
      { value: 5, label: 'Micropigmentación' },
      { value: 6, label: 'Estética' },
      { value: 7, label: 'Seminarios' }
    ];
    // Filtrar según los servicios del estudio
    this.tiposTrabajo = this.tiposTrabajo.filter(tipo => {
      if (tipo.value === 1) return true;
      if (tipo.value === 2) return this.estudio.servicio_walkin === 1;
      if (tipo.value === 3) return this.estudio.servicio_piercing === 1;
      if (tipo.value === 4) return this.estudio.servicio_laser === 1;
      if (tipo.value === 5) return this.estudio.servicio_micro === 1;
      if (tipo.value === 6) return this.estudio.servicio_manicura === 1;
      if (tipo.value === 7) return this.estudio.servicio_seminarios === 1;
      return false;
    });
    // Inicializar todas las fases
    const todasLasFases: OpcionSelect[] = [
      { value: 1, label: 'Pre-proyecto', tipoProyecto: [1] },
      { value: 2, label: 'Boceto', tipoProyecto: [1] },
      { value: 3, label: 'Tatuaje', tipoProyecto: [1] },
      { value: 4, label: 'Repaso Tattoo', tipoProyecto: [1] },
      { value: 5, label: 'Labios', tipoProyecto: [5] },
      { value: 6, label: 'Cejas', tipoProyecto: [5] },
      { value: 7, label: 'Eyeliner', tipoProyecto: [5] },
      { value: 8, label: 'Microblading', tipoProyecto: [5] },
      { value: 9, label: 'Elim. Tattoo & Micro', tipoProyecto: [4] },
      { value: 10, label: 'Elim. Eyeliner', tipoProyecto: [4] },
      { value: 11, label: 'Repaso Micro', tipoProyecto: [5] },
      { value: 12, label: 'Cursos Micro', tipoProyecto: [5] },
      { value: 13, label: 'Shading', tipoProyecto: [5] },
      { value: 14, label: 'Manten. Cejas', tipoProyecto: [5] },
      { value: 15, label: 'Manten. Ojos', tipoProyecto: [5] },
      { value: 16, label: 'Manten. Labios', tipoProyecto: [5] },
      { value: 17, label: 'Elim. Cejas', tipoProyecto: [4] },
      { value: 18, label: 'Elim. Labios', tipoProyecto: [4] },
      { value: 19, label: 'Manicura', tipoProyecto: [6] },
      { value: 20, label: 'Ext. Pestañas', tipoProyecto: [6] },
      { value: 21, label: 'Ext. Pestañas retoque', tipoProyecto: [6] },
      { value: 22, label: 'Carbon Peel', tipoProyecto: [6] },
      { value: 23, label: 'Rejuv. Facial', tipoProyecto: [6] },
      { value: 24, label: 'Curso Tatuaje', tipoProyecto: [7] },
      { value: 25, label: 'Curso Piercing', tipoProyecto: [7] },
      { value: 26, label: 'Curso Láser', tipoProyecto: [7] },
      { value: 27, label: 'Curso Micro', tipoProyecto: [7] },
      { value: 28, label: 'Depilacion facial', tipoProyecto: [6] },
      { value: 29, label: 'Lifting Pestañas', tipoProyecto: [6] },
      { value: 30, label: 'Laminado cejas', tipoProyecto: [6] },
      { value: 31, label: 'Gemas dentales', tipoProyecto: [6] },
      { value: 32, label: 'Otros', tipoProyecto: [5] }
    ];
    //Determinar si mostrarmos el check box y hora inicio/fin para crear una ausencia con rango de horas
    this.determinarSiMostrarCheckbox();

    this.todasLasFases = todasLasFases;
    this.actualizarFasesCita();
  }

  cargarArtistas() {
    this.api.getArtistasNew(this.estudio_id).subscribe((data: any) => {
      this.artistas = data.artistas;
    });
  }

  seleccionarOpcion(opcion: string) {
    this.opcionSeleccionada = opcion;

    if (opcion === 'Nueva ausencia de artista') {
      // Mostrar el formulario para crear una ausencia
      this.mostrandoFormularioAusencia = true;
    } else if (opcion === 'Nuevo evento') {
      // Mostrar el formulario para crear un evento
      this.mostrandoFormulario = true;
    } else if (opcion === 'Nuevo trabajo') {
      // Mostrar el formulario para crear un trabajo
      this.mostrandoFormularioTrabajo = true;
      // Prellenar los campos de fecha, hora y duración según selectionInfo
      this.prellenarDatosTrabajo();
    } else {
      // Otras opciones (no implementadas aún)
      this.activeModal.close({ opcionSeleccionada: opcion, selectionInfo: this.selectionInfo });
    }
  }

  cancelar() {
    if (this.mostrandoFormularioTrabajo || this.mostrandoFormularioAusencia || this.mostrandoFormulario) {
      // Si estamos en el formulario, volvemos a las opciones
      this.mostrandoFormularioTrabajo = false;
      this.mostrandoFormularioAusencia = false;
      this.mostrandoFormulario = false;
    } else {
      // Si estamos en las opciones, cerramos el modal
      this.activeModal.dismiss('cancel');
    }
  }

  //Determinar si mostrarmos el check box y hora inicio/fin para crear una ausencia con rango de horas
  determinarSiMostrarCheckbox() {
    // Verificar si allDay es true y la diferencia entre end y start es de un día
    const start = moment(this.selectionInfo.start);
    const end = moment(this.selectionInfo.end);
    const diffDays = end.diff(start, 'days');

    if (this.selectionInfo.allDay && diffDays === 1) {
        // Es una selección de un solo día en la vista "mes"
        this.mostrarCheckboxAusenciaPorHoras = true;
    } else {
        this.mostrarCheckboxAusenciaPorHoras = false;
    }
  }

  guardarAusencia() {
    // Validar los datos del formulario
    if (!this.ausenciaData.artista_id) {
      alert('Por favor, selecciona un artista.');
      return;
    }

    // Si es una ausencia con rango de horas
    if (this.ausenciaData.ausencia_por_horas) {
      if (!this.ausenciaData.hora_inicio || !this.ausenciaData.hora_fin) {
          alert('Por favor, completa la hora de inicio y fin.');
          return;
      }
      // Validar que hora_fin sea posterior a hora_inicio
      const inicio = moment(this.ausenciaData.hora_inicio, 'HH:mm');
      const fin = moment(this.ausenciaData.hora_fin, 'HH:mm');
      if (!fin.isAfter(inicio)) {
          alert('La hora de fin debe ser posterior a la hora de inicio.');
          return;
      }
    }

    // Preparar los datos para enviar al componente padre
    const data = {
      opcionSeleccionada: this.opcionSeleccionada,
      selectionInfo: this.selectionInfo,
      ausenciaData: this.ausenciaData
    };

    // Cerrar el modal y enviar los datos al componente padre
    this.activeModal.close(data);
  }

  guardarEvento() {
    // Validar los datos del formulario
    if (!this.eventoData.artista_id || !this.eventoData.descripcion) {
      alert('Por favor, completa todos los campos.');
      return;
    }

    // Preparar los datos para enviar al componente padre
    const data = {
      opcionSeleccionada: this.opcionSeleccionada,
      selectionInfo: this.selectionInfo,
      eventoData: this.eventoData
    };

    // Cerrar el modal y enviar los datos al componente padre
    this.activeModal.close(data);
  }

  prellenarDatosTrabajo() {
    const startDate = moment(this.selectionInfo.start);
    const endDate = moment(this.selectionInfo.end);

    this.trabajoData.fecha = startDate.format('YYYY-MM-DD');
    this.trabajoData.horai = startDate.format('HH:mm');
    this.trabajoData.horaf = endDate.format('HH:mm');
    this.trabajoData.duracion = endDate.diff(startDate, 'minutes');

    // Si hay resourceId (cabina), lo asignamos
    if (this.selectionInfo.resource) {
      this.trabajoData.cabina_id = this.selectionInfo.resource.id;
    }
    //console.log('Datos de la selección:', this.trabajoData);
  }

  //Gestión de tipos proyecto y fases cita
  onTipoTrabajoChange() {
    this.actualizarFasesCita();
  }
  actualizarFasesCita() {
    if (this.trabajoData.tipo_proyecto) {
      this.fasesCita = this.todasLasFases.filter(fase =>
        fase.tipoProyecto.includes(this.trabajoData.tipo_proyecto)
      );
    } else {
      this.fasesCita = [];
    }
    this.trabajoData.fase_cita = null;
  }
  //Fin de gestión de tipos proyecto y fases cita
  
  selectCliente(target: HTMLElement) {
    // Desenfocar el elemento
    target.blur();
    const modalRef = this.modalService.open(ClienteListadoComponent, { windowClass: 'p-5 mt-5 mt-md-0' });
    this.cdRef.detectChanges();
    modalRef.componentInstance.clienteSelected.subscribe(clienteId => {
      // Obtener los datos del cliente seleccionado desde el backend
      this.api.getClienteNew(clienteId).subscribe(
        (response: any) => {
          // Asignar solo la propiedad 'data' al cliente seleccionado
          this.selectedCliente = response.data;          
          // Actualizar el campo del formulario con el ID del cliente
          this.trabajoData.cliente_id = this.selectedCliente.id;
        },
        (error) => {
          console.error('Error al obtener el cliente:', error);
        }
      );
    });
  }
  clearSelectedCliente() {
    this.selectedCliente = null;
    this.trabajoData.cliente_id = null;
  }

  // Función para calcular la hora fin a partir de hora inicio y duración
  calcularHoraFin() {
    if (this.trabajoData.horai && this.trabajoData.duracion) {
      if (this.trabajoData.duracion <= 0) {
        this.trabajoData.horaf = this.trabajoData.horai;
        return;
      }
      const horaiMoment = moment(this.trabajoData.horai, 'HH:mm');
      const horafMoment = horaiMoment.clone().add(this.trabajoData.duracion, 'minutes');
      this.trabajoData.horaf = horafMoment.format('HH:mm');
      this.restablecerVerificacion();
    }
  }
  
  // Función para actualizar la comisión del artista seleccionado
  artistaSeleccionado(artistaId: number) {
    const artistaIdNumber = Number(artistaId);
    const artistaSeleccionado = this.artistas.find(artista => artista.id === artistaIdNumber);
    if (artistaSeleccionado) {
      this.trabajoData.comision_artista = artistaSeleccionado.porcentaje_comision; // Asegúrate de usar la propiedad correcta
    } else {
      console.error('Artista no encontrado', artistaId);
    }
  }
  
  // Verificación de solapamiento entre citas y cabinas
  verificarSolapamiento() {
    // Verificar que los campos necesarios están llenos
    if (!this.trabajoData.cabina_id || !this.trabajoData.fecha || !this.trabajoData.horai || !this.trabajoData.duracion) {
      this.toastr.error('Debe seleccionar cabina, fecha, hora de inicio y duración para verificar solapamiento.', 'Error');
      return;
    }
    // Cambiar el estado a 'verificando'
    this.estadoVerificacion = 'verificando';
  
    // Calcular la hora de fin si no está ya calculada
    if (!this.trabajoData.horaf) {
      this.calcularHoraFin();
    }
    const payload = {
      estudio_id: this.user.estudio_id,
      cabina_id: this.trabajoData.cabina_id,
      fecha: this.trabajoData.fecha,
      horai: this.trabajoData.horai,
      horaf: this.trabajoData.horaf
    };
    this.api.verificarSolapamiento(payload).subscribe(
      (response: any) => {
        if (response.success) {
          if (response.overlap) {
            // Existe solapamiento
            this.solapamiento = true;
            this.estadoVerificacion = 'con-solapamiento';
          } else {
            // No existe solapamiento
            this.solapamiento = false;
            this.estadoVerificacion = 'sin-solapamiento';
          }
        } else {
          this.toastr.error(response.message || 'Error al verificar solapamiento.', 'Error');
          this.estadoVerificacion = 'no-verificado';
        }
      },
      (error) => {
        this.toastr.error('Ocurrió un error al verificar solapamiento.', 'Error');
        this.estadoVerificacion = 'no-verificado';
      }
    );
  }
  restablecerVerificacion() {
    this.verificacionRealizada = false;
    this.solapamiento = false;
    this.estadoVerificacion = 'no-verificado';
  }
  onCabinaChange() {
    this.restablecerVerificacion();
  }
  
  onFechaChange() {
    this.restablecerVerificacion();
  }
  onHoraiChange() {
    this.restablecerVerificacion();
  }
  onDuracionChange() {
    this.calcularHoraFin();
    this.restablecerVerificacion();
  }

  // Verificando si existe un cliente con mismo número o email
  // Si existe, damos la opción de marcalo como cliente de la cita
  onTelefonoEmailChange() {
    const telefono = this.trabajoData.cliente.telefono;
    const email = this.trabajoData.cliente.email;
  
    if ((telefono && telefono.trim() !== '') || (email && email.trim() !== '')) {
      this.mostrarBotonVerificarCliente = true;
      this.estadoVerificacionCliente = 'no-verificado';
    } else {
      this.mostrarBotonVerificarCliente = false;
      this.estadoVerificacionCliente = 'no-verificado';
      this.clientesCoincidentes = [];
    }
  }
  verificarClienteExistente() {
    const telefono = this.trabajoData.cliente.telefono;
    const email = this.trabajoData.cliente.email;
  
    if (!telefono && !email) {
      this.toastr.error('Debe proporcionar el teléfono o el email para realizar la verificación.', 'Error');
      return;
    }
    this.estadoVerificacionCliente = 'verificando';
    const payload = {
      estudio_id: this.user.estudio_id,
      telefono: telefono,
      email: email
    };
    this.api.buscarClientesCoincidentes(payload).subscribe(
      (response: any) => {
        if (response.success) {
          if (response.clientesEncontrados) {
            this.clientesCoincidentes = response.clientes;
            this.estadoVerificacionCliente = 'con-coincidencias';
          } else {
            this.clientesCoincidentes = [];
            this.estadoVerificacionCliente = 'sin-coincidencias';
          }
        } else {
          this.toastr.error(response.message || 'Error al verificar el cliente.', 'Error');
          this.estadoVerificacionCliente = 'no-verificado';
        }
      },
      (error) => {
        this.toastr.error('Ocurrió un error al verificar el cliente.', 'Error');
        this.estadoVerificacionCliente = 'no-verificado';
      }
    );
  }
  asignarClienteExistente(cliente: any) {
    this.trabajoData.cliente_nuevo = false;
    this.trabajoData.cliente_id = cliente.id;
    this.selectedCliente = cliente;
    // Limpiar los datos del cliente nuevo
    this.trabajoData.cliente = {
      nombre: '',
      apellido1: '',
      telefono: '',
      email: '',
      es_ingles: false
    };
    // Ocultar el formulario de cliente nuevo y los resultados de búsqueda
    this.mostrarBotonVerificarCliente = false;
    this.estadoVerificacionCliente = 'no-verificado';
    this.clientesCoincidentes = [];
  }
  // FIN Verificando si existe un cliente con mismo número o email
  
  
  
  


  guardarTrabajo() {
    // Validar los datos del formulario
    if (!this.validarFormTrabajo()) {
      return;
    }

    // Preparar los datos para enviar al componente padre
    const data = {
      opcionSeleccionada: this.opcionSeleccionada,
      selectionInfo: this.selectionInfo,
      trabajoData: this.trabajoData
    };

    // Cerrar el modal y enviar los datos al componente padre
    this.activeModal.close(data);
  }

  validarFormTrabajo(): boolean {
    // Reiniciar mensajes de error
    let esValido = true;
  
    // Validación 1: Verificar que se ha seleccionado o creado un cliente
    if (this.trabajoData.cliente_nuevo) {
      // Si se está creando un cliente nuevo, verificar que el nombre está proporcionado
      if (!this.trabajoData.cliente.nombre || this.trabajoData.cliente.nombre.trim() === '') {
        this.toastr.error('Escribe al menos el nombre del nuevo cliente', 'Error');
        esValido = false;
      }
      // Validación adicional: Verificar que el email tiene un formato correcto
      if (this.trabajoData.cliente.email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(this.trabajoData.cliente.email)) {
          this.toastr.error('El formato del email no es correcto', 'Error');
          esValido = false;
        }
      }
    } else {
      // Si no se está creando un cliente nuevo, verificar que se ha seleccionado uno existente
      if (!this.trabajoData.cliente_id) {
        this.toastr.error('Es necesario seleccionar un cliente existente o crear uno nuevo', 'Error');
        esValido = false;
      }
    }

    // Validación 2: Si el estudio tiene cabinas, verificar que se ha seleccionado una
    if (this.cabinas && this.cabinas.length > 0 && !this.trabajoData.cabina_id) {
      this.toastr.error('Es necesario seleccionar una cabina para el trabajo', 'Error');
      esValido = false;
    }
  
    // Validación 3: La hora de fin no puede ser menor o igual a la hora de inicio
    const horaInicio = moment(this.trabajoData.horai, 'HH:mm');
    const horaFin = moment(this.trabajoData.horaf, 'HH:mm');
    if (!horaFin.isAfter(horaInicio)) {
      this.toastr.error('La hora de fin debe ser mayor que la hora de inicio', 'Error');
      esValido = false;
    }
  
    // Validación 4: Los campos 'senal' y 'precio' no pueden ser negativos
    if (this.trabajoData.senal < 0) {
      this.toastr.error('El valor de la señal no puede ser negativo', 'Error');
      esValido = false;
    }
    if (this.trabajoData.precio < 0) {
      this.toastr.error('El precio no puede ser negativo', 'Error');
      esValido = false;
    }
  
    const tiposConFaseCita = [1, 4, 5, 6];
    if (
      this.trabajoData.tipo_proyecto &&
      tiposConFaseCita.includes(this.trabajoData.tipo_proyecto) &&
      !this.trabajoData.fase_cita
    ) {
      this.toastr.error('Es necesario seleccionar una fase', 'Error');
      esValido = false;
    }

    // Validación 6: Verificar que se ha seleccionado un 'tipo_proyecto'
    if (!this.trabajoData.tipo_proyecto) {
      this.toastr.error('Hay que indicar el tipo de proyecto', 'Error');
      esValido = false;
    }
    
    // Verificar que se ha seleccionado un artista
    if (!this.trabajoData.artista_id) {
      this.toastr.error('Debes seleccionar un artista', 'Error');
      esValido = false;
    }
  
    // Validación 7: 'comision_artista' debe ser un número entre 0 y 100
    const comision = this.trabajoData.comision_artista;
    if (comision === null || comision === undefined || comision === '') {
      this.toastr.error('Hay que indicar la comisión del artista seleccionado', 'Error');
      esValido = false;
    } else if (!Number.isInteger(+comision) || comision < 0 || comision > 100) {
      this.toastr.error('La comisión del artista debe ser un número entero entre 0 y 100', 'Error');
      esValido = false;
    }
  
    return esValido;
  }
  
  





}
