import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApicallsService } from '../../services/apicalls.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import * as global from '../../services/global.service';


@Component({
  selector: 'app-pause',
  templateUrl: './pause.component.html'
})
export class PauseComponent implements OnInit {

  formPause!: UntypedFormGroup;
  user:any;
  global_url:any;
  constructor(private formBuilder: UntypedFormBuilder,
              private route: ActivatedRoute,
              private toastr: ToastrService,
              private api: ApicallsService,
              private router: Router,
              private auth: AuthenticationService) {

  }

  
  ngOnInit() {
    this.user = this.auth.currentUserValue;
    this.global_url = global.API_URL;
    this.formPause = this.formBuilder.group({
      password: ['', Validators.required]
    });


  }
  logout() {
    this.auth.logout();
  }

  desbloquear(formValue: any){
    this.api.desbloquear(formValue, this.user.id).subscribe((data: any) => {
      if(data.desbloqueo){
        localStorage.removeItem('pause');
        this.router.navigate(['/dashboard']);
      }else{
        this.toastr.error('Contraseña incorrecta', 'Desbloquear sesión');
      }
    });
  }

}
