
<app-loading [ngClass]="{'active' : loader == true}"></app-loading>

<div class="row">
    <div class="col-lg-12">
        <div class="card mb-0">
            <div class="card-body pt-0">
                
                <div class="row align-items-center mt-3 mb-3">
                    <div class="col-12">
                        <div class="filtro_clientes">
                            <div class="search-box buscador_de_clientes">
                                <div class="position-relative">
                                    <input type="text" class="form-control border border-dark" placeholder="Buscar cliente..." [(ngModel)]='searchTerm'>
                                    <i class="bx bx-search-alt search-icon"></i>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
                <button class="btn btn-primary btn-block mb-3" (click)="invertirOrden()"><i class="bx bx-git-compare"></i> Invertir orden de aparición</button>                
                <div class="table-responsive">
                    <table class="table table-centered table-nowrap table-hover tabla-clientes">
                        <tbody>
                            <tr *ngIf="clientes?.length < 1">
                                <td colspan="7" class="text-center bg-soft-light">
                                    <i class="d-block bx bx-user-x align-middle h1 mb-0"></i> 
                                    <p class="mb-0 text-secondary font-size-11">No hay clientes todavía.</p>
                                </td>
                            </tr>
                            <tr *ngFor="let item of clientesFilter|slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                                <td>
                                    <a (click)="selectCliente(item.id)" class="text-dark font-size-14 font-weight-medium mb-1 nombrecliente">
                                        {{ item.nombre }} {{ item.apellido1 }} {{ item.apellido2 }} <i *ngIf="item.conflictivo == 1" class="mdi mdi-alert text-danger" ngbPopover="Cliente conflictivo por {{ item.motivo_conflicto }}" triggers="mouseenter:mouseleave"></i>
                                    </a>                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
  