export class Zona
{
    constructor() {}

    static get_list()
    {
        return [   
            { id : 1,  nombre : 'Abdomen' },
            { id : 2,  nombre : 'Antebrazo interior izquierdo' },
            { id : 3,  nombre : 'Antebrazo exterior izquierdo' },
            { id : 59,  nombre : 'Antebrazo interior derecho' },
            { id : 60,  nombre : 'Antebrazo exterior derecho' },
            { id : 4,  nombre : 'Brazo derecho' },
            { id : 5,  nombre : 'Brazo izquierdo' },
            { id : 6,  nombre : 'Cabeza' },
            { id : 7,  nombre : 'Cara' },
            { id : 8,  nombre : 'Ceja derecha' },
            { id : 9,  nombre : 'Ceja izquierda' },
            { id : 10,  nombre : 'Clavícula' },
            { id : 11,  nombre : 'Costillas derecha' },
            { id : 12,  nombre : 'Costillas izquierda' },
            { id : 13,  nombre : 'Cuello' },
            { id : 65,  nombre : 'Nuca' },
            { id : 14,  nombre : 'Dedos mano derecha' },
            { id : 15,  nombre : 'Dedos mano izquierda' },
            { id : 16,  nombre : 'Empeine derecho' },
            { id : 17,  nombre : 'Empeine izquierdo' },
            { id : 18,  nombre : 'Espalda' },
            { id : 19,  nombre : 'Frenillo labio superior' },
            { id : 20,  nombre : 'Gemelo derecho' },
            { id : 21,  nombre : 'Gemelo izquierdo' },
            { id : 22,  nombre : 'Genital femenino' },
            { id : 23,  nombre : 'Genital masculino' },
            { id : 24,  nombre : 'Gluteo derecho' },
            { id : 25,  nombre : 'Gluteo izquierdo' },
            { id : 26,  nombre : 'Hombro derecho' },
            { id : 27,  nombre : 'Hombro izquierdo' },
            { id : 28,  nombre : 'Labio inferior' },
            { id : 29,  nombre : 'Labio superior' },
            { id : 30,  nombre : 'Lengua' },
            { id : 31,  nombre : 'Mano derecha' },
            { id : 32,  nombre : 'Mano izquierda' },
            { id : 33,  nombre : 'Mejilla derecha' },
            { id : 34,  nombre : 'Mejilla izquierda' },
            { id : 35,  nombre : 'Muslo derecho' },
            { id : 36,  nombre : 'Muslo izquierdo' },
            { id : 37,  nombre : 'Muñeca derecha' },
            { id : 38,  nombre : 'Muñeca izquierda' },
            { id : 39,  nombre : 'Nariz' },
            { id : 40,  nombre : 'Ombligo' },
            { id : 41,  nombre : 'Oreja derecha' },
            { id : 42,  nombre : 'Oreja izquierda' },
            { id : 66,  nombre : 'Párpados' },
            { id : 43,  nombre : 'Pecho' },
            { id : 44,  nombre : 'Pectoral derecho' },
            { id : 45,  nombre : 'Pectoral izquierdo' },
            { id : 46,  nombre : 'Pelvis' },
            { id : 47,  nombre : 'Pezón derecho' },
            { id : 48,  nombre : 'Pezón izquierdo' },
            { id : 49,  nombre : 'Pierna derecha' },
            { id : 50,  nombre : 'Pierna izquierda' },
            { id : 51,  nombre : 'Planta pie derecho' },
            { id : 52,  nombre : 'Planta pie izquierdo' },
            { id : 53,  nombre : 'Rabadilla' },
            { id : 54,  nombre : 'Rodilla derecha' },
            { id : 55,  nombre : 'Rodilla izquierda' },
            { id : 56,  nombre : 'Tobillo derecho' },
            { id : 57,  nombre : 'Tobillo izquierdo' },
            { id : 58,  nombre : 'Trapecio' },
            { id : 61,  nombre : 'Bíceps izquierdo' },
            { id : 62,  nombre : 'Bíceps derecho' },
            { id : 63,  nombre : 'Hombro a codo izquierdo' },
            { id : 64,  nombre : 'Hombro a codo derecho' },
            
        ];
            
    }

    static get_texto( valor : number )
    {
        const listado = this.get_list();
        let rtn_value = '';
        listado.forEach( item => {
            if ( item.id == valor) 
            {
                rtn_value = item.nombre;
            }
        });

        return rtn_value;
    }
}