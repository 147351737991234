<div>
    <div class="p-0">
        <div class="row no-gutters">
            
            <div class="d-none d-md-block col-md-7 col-lg-8 col-xl-9">
                <div class="auth-full-bg bg-soft-dark">
                    <div class="w-100">
                        <div class="bg-overlay"></div>
                        <div class="vh-100 d-flex align-items-end">
                            <div class="w-100 pb-5">
                                <div class="row justify-content-center">
                                    <div class="col-12">
                                        <div class="text-center text-light">
                                            
                                            <h5 class="mb-3 text-white">Los grandes ya trabajan con Inkoru</h5>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end col -->

            <div class="col-12 col-md-5 col-lg-4 col-xl-3">
                <div class="auth-full-page-content p-md-5 p-4 bg-light">
                    <div class="w-100">

                        <div class="d-flex flex-column h-100">
                            <div class="mb-4 mb-md-5">
                                <a href="index.html" class="d-block auth-logo">
                                    <img src="assets/images/logo-dark.png" alt="" height="40" class="auth-logo-dark">
                                    <img src="assets/images/logo-light.png" alt="" height="40" class="auth-logo-light">
                                </a>
                            </div>
                            <div class="my-auto">
                                
                                <div *ngIf="loginform">
                                    <h5 class="text-dark font-weight-bold">Acceder a Inkoru</h5>
                                </div>

                                <div class="mt-4" *ngIf="loginform">
                                    <form class="form-horizontal" [formGroup]="formLogin" >
        
                                        <div class="form-group">
                                            <label for="username">Email</label>
                                            <input type="text" class="form-control" id="username" formControlName="username" placeholder="Escribe tu correo electrónico">
                                        </div>
                
                                        <div class="form-group">
                                            <label for="userpassword">Contraseña</label>
                                            <input type="password" class="form-control" id="userpassword" formControlName="password" placeholder="Escribe tu contraseña">
                                        </div>
                
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="customControlInline">
                                            <label class="custom-control-label" for="customControlInline">Recuérdame</label>
                                        </div>
                                        
                                        <div class="mt-3">
                                            <button class="btn btn-dark btn-block waves-effect waves-light" (click)="login(formLogin.value)"><i class="bx bx-log-in align-middle font-size-18"></i> Entrar</button>
                                        </div>
            
        
                                        <div class="mt-4 text-center">
                                            <a (click)="showRecoverForm()" class="text-muted psw-olvidado"><i class="mdi mdi-lock mr-1"></i> ¿Has olvidado tu contraseña?</a>
                                        </div>
                                    </form>
                                </div>
                                <div *ngIf="recoverform">
                                    <h5 class="text-dark font-weight-bold">Contraseña olvidada</h5>
                                    <p class="text-muted">Restablece desde aquí tu contraseña.</p>
                                </div>
                                <div *ngIf="recoverform" class="mt-4">
                                    <div class="alert alert-warning mb-0" role="alert">
                                        Escribe tu email y te enviaremos una contraseña de acceso temporal que podrás modificar más tarde.
                                    </div>
                                </div>
                                <div class="mt-4" *ngIf="recoverform">
                                    <form class="" [formGroup]="formForgot!" >
                                        <div class="form-group">
                                            <label for="username">Email</label>
                                            <input type="text" class="form-control" id="username" formControlName="username" placeholder="Escribe tu email">
                                        </div>
                                        <div class="row m-t-20">
                                            <div class="col-12">
                                                <button class="btn btn-dark btn-block waves-effect waves-light" (click)="sendForgot(formForgot.value)"><i class="bx bx-reset align-middle font-size-18"></i> Restablecer contraseña</button>
                                            </div>
                                        </div>
                                        <div class="mt-2 text-center">
                                            <a (click)="showRecoverForm()" class="btn btn-secondary btn-block waves-effect waves-light"><i class="bx bxs-left-arrow-square align-middle font-size-18"></i> Volver</a>
                                        </div>
                                    </form>
                                </div>
                                <div class="mt-5 text-center">
                                    <p>¿No tienes cuenta todavía? <u>Si eres propietario de un estudio de tatuajes en España</u>, <a [routerLink]="[ '/register' ]"  class="font-weight-bold text-dark"> regístrate aquí</a>.</p>
                                </div>
                            </div>

                            <div class="mt-4 mt-md-5 text-center">
                                <p class="mb-0">© <script>document.write(new Date().getFullYear())</script> Inkoru - Tattoo Management</p>
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </div>
    <!-- end container-fluid -->
</div>