<div class="account-pages my-5 pt-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="text-center mb-5">
                    <h4 class="text-uppercase">Estamos perdidísimos...</h4>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div>
                    <img src="assets/images/perdidos.jpg" alt="" class="img-fluid">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="text-center mb-5">
                    <div class="mt-5 text-center">
                        <a class="btn btn-dark waves-effect waves-light" href="/">Vuelve al inicio</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>