<div class="auth-body-bg">
    <div>
        <div class="container-fluid p-0">
            <div class="row no-gutters">
                <div class="d-none d-md-block col-md-7 col-lg-8 col-xl-9">
                    <div class="auth-full-bg bg-soft-dark">
                        <div class="w-100">
                            <div class="bg-overlay"></div>
                            <div class="vh-100 d-flex align-items-end">
                                <div class="w-100 pb-5">
                                    <div class="row justify-content-center">
                                        <div class="col-12">
                                            <div class="text-center text-light">

                                                <h5 class="mb-3 text-white">Los grandes ya trabajan con Inkoru</h5>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end col -->

                <div class="col-12 col-md-5 col-lg-4 col-xl-3">
                    <div class="auth-full-page-content p-md-5 p-4 bg-light">
                        <div class="w-100">
    
                            <div class="d-flex flex-column h-100">
                                <div class="mb-4 mb-md-5">
                                    <a href="index.html" class="d-block auth-logo">
                                        <img src="assets/images/logo-dark.png" alt="" height="40" class="auth-logo-dark">
                                        <img src="assets/images/logo-light.png" alt="" height="40" class="auth-logo-light">
                                    </a>
                                </div>
                                <div class="my-auto">
                                    
                                    <div>
                                        <h5 class="text-dark font-weight-bold">Regístrate</h5>
                                        <p class="text-muted"><u>Si eres propietario de un estudio de tatuajes</u>, ya puedes crear tu cuenta.</p>
                                    </div>
        
                                    <div class="mt-4">
                                        <form class="form-horizontal" [formGroup]="formRegister">

                                            <div class="form-group">
                                                <label for="name">Nombre (<span class="font-weight-bold">sin apellidos</span>)</label>
                                                <input type="text" class="form-control" id="name" formControlName="name" placeholder="Tu nombre - sin apellidos">
                                            </div>
                                            <div class="form-group">
                                                <label for="email">Email</label>
                                                <input type="email" class="form-control" id="email" formControlName="email" placeholder="Email del propietario">
                                            </div>
                                            <div class="form-group">
                                                <label for="userpassword">Contraseña</label>
                                                <input type="password" class="form-control" id="userpassword" formControlName="password" placeholder="Escribe tu contraseña">
                                            </div>
                                            <div class="form-group">
                                                <label for="userpassword_confirm">Repite tu contraseña</label>
                                                <input type="password" class="form-control" id="userpassword_confirm" formControlName="password_confirm" placeholder="Repite tu contraseña">
                                            </div>
                                            <div class="form-group">
                                                <label for="promo">Código Promocional</label>
                                                <input type="text" class="form-control" id="promo" formControlName="promo" placeholder="¿Tienes un código promocional?">
                                            </div>
                                            <div>
                                                <p class="mb-0">Registrándote en Inkoru confirmas que aceptas nuestros <a href="#terminos" data-toggle="modal" data-target="#terminos" class="font-weight-bold text-dark">Términos de uso</a></p>
                                            </div>
                                            <!--Modal Términos-->
                                            <div class="modal fade" id="terminos" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="terminosLabel" aria-hidden="true">
                                                <div class="modal-dialog modal-dialog-scrollable">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title" id="terminosLabel">Términos de uso</h5>
                                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </div>
                                                        <div class="modal-body">
                                                            <p class="font-weight-bold text-dark font-size-15">Condiciones de uso</p>
                                                            <p>Inkoru es un servicio online para gestionar diferentes aspectos de un estudio de tatuajes. Las presentes condiciones exponen los derechos y obligaciones del usuario y de Inkoru como proveedor del servicio.</p>
                                                            <p>Inkoru supondrá que cualquier usuario que utilice el servicio, ha leído y aceptado las condiciones de uso, además Inkoru se reserva el derecho de actualizar y modificar las Condiciones de uso sin ningún tipo de aviso previo, estando éstos disponibles siempre en la dirección web www.inkoru.com.</p>
                                                            <p>El presente acuerdo tendrá validez a partir de la fecha en la que marque la casilla de aceptación de este acuerdo que aparece en el pié de este documento.Las nuevas características que pudieran añadirse a los Servicios estarán sujetas a las Condiciones de uso. En caso de que el usuario continúe haciendo uso del Servicio después de cualquier modificación, supondrá su conformidad con dichas modificaciones. Siempre se podrá consultar la versión más reciente de las condiciones de uso en http://www.inkoru.com/tyc</p>
                                                            <p>El incumplimiento de cualquiera de las Condiciones de uso podrá ser causa de anulación de su cuenta.</p>
                                                            <p class="font-weight-bold text-dark font-size-15">Condiciones de la cuenta</p>
                                                            <p>Para acceder al servicio es imprescindible ser mayor de dieciocho años (18).</p>
                                                            <p>Se deberá proporcionar un nombre legal, una dirección de correo electrónico, y toda la información requerida por Inkoru en el proceso de creación de la cuenta.</p>
                                                            <p>Es responsabilidad del usuario proporcionar información veraz; Inkoru se reserva el derecho de eliminar cualquier cuenta, si se sospecha de su veracidad o pudiera incumplir alguna de las normas de uso de Inkoru.</p>
                                                            <p>El usuario es responsable de mantener la privacidad de su cuenta. Inkoru no se hará responsable de cualquier daño o pérdida que pueda ser consecuencia de un error del usuario al proteger su información de acceso.</p>
                                                            <p>Las partes aceptan equiparar jurídicamente la firma autógrafa del cliente a la realizada mediante cualquier otro tipo de clave, código o elemento de seguridad identificativo. Sin perjuicio de lo anterior el prestador de los Servicios podrá exigir cuando lo considere necesario confirmación por escrito al cliente.</p>
                                                            <p class="font-weight-bold text-dark font-size-15">Tipos de contrato</p>
                                                            <p>La contratación de un plan de pago de Inkoru se inicia en el momento en el que el usuario introduce los datos de la tarjeta y realiza un primer pago. En ningún caso se realizarán abonos o devoluciones. Los límites de cada arancel se especifican en la sección de precios de inkoru.com/precio.</p>
                                                            <p>Inkoru se reserva el derecho de modificar las tarifas unilateralmente y en cualquier momento, sin conceder a dicho cambio ningún derecho de compensación por parte de los usuarios.</p>
                                                            <p class="font-weight-bold text-dark font-size-15">Pago y acceso</p>
                                                            <p>Para realizar los pagos, es necesario presentar una tarjeta bancaria válida. Previamente sería necesario crear una cuenta gratuita. Inkoru cobrará periódicamente al cliente una tarifa recurrente dependiendo del tipo de cuenta que haya contratado.</p>
                                                            <p>El Servicio se cobra cada período por adelantado y no es reembolsable. No habrá reembolsos o créditos por meses parciales del Servicio, ni habrá reembolsos en caso de que el usuario no utilice el Servicio durante el período de tiempo en que la cuenta esté abierta. En ningún caso se harán excepciones.</p>
                                                            <p>El cliente acepta que no será necesario confirmar la recepción de la aceptación del contrato cuando se haya celebrado exclusivamente mediante comunicación electrónica.</p>
                                                            <p>En caso de impago o devolución del recibo, se notificará al cliente del incumplimiento y perderá automáticamente la capacidad de acceder al Servicio. Los datos se podrán eliminar en un plazo de treinta (30) días a partir de la fecha de incumplimiento.</p>
                                                            <p>Ninguna tarifa incluye los impuestos o derechos requeridos por sus autoridades gubernamentales. El usuario es responsable del pago de estos impuestos u obligaciones.</p>
                                                            <p class="font-weight-bold text-dark font-size-15">Modificaciones del servicio y de los planes</p>
                                                            <p>Inkoru se reserva el derecho de modificar o suspender, temporal o permanentemente, el Servicio en cualquier momento y por cualquier motivo con o sin previo aviso si lo considera conveniente. Inkoru se reserva el derecho de cambiar las cuotas mensuales con un aviso de 15 días. La notificación de los cambios de cuota se publicará en el sitio web de Inkoru y por escrito.</p>
                                                            <p class="font-weight-bold text-dark font-size-15">Cancelación y rescisión</p>
                                                            <p>El usuario es responsable de la correcta cancelación de su suscripción, pudiendo cancelar su suscripción en cualquier momento a través del menú 'Mis datos' > pestaña 'Facturación'. Una vez cancelada la suscripción, todo su contenido se eliminará después de treinta (30) días. El usuario puede cancelar su suscripción en cualquier momento, pero será responsable de todos los cargos realizados hasta ese momento, incluyendo el cargo mensual completo por el mes en el que suspenda el servicio. A partir de entonces, no se le cobrará. Inkoru se reserva el derecho de cancelar una cuenta o impedir el uso del Software a aquellos que no cumplan con las presentes condiciones de uso.</p>
                                                            <p class="font-weight-bold text-dark font-size-15">Derecho de copia y propiedad intelectual</p>
                                                            <p>Inkoru posee todos los derechos de la Propiedad Intelectual de todos y cualquiera de los componentes del Servicio que puedan protegerse, incluyendo pero no limitándose al nombre del Servicio, material gráfico, todo el software asociado con el Servicio y los elementos de la interfaz de usuario contenidos en el Servicio, muchas de las características individuales y la documentación relacionada. El usuario se compromete a no copiar, adaptar, reproducir, distribuir, aplicar ingeniería inversa, decompilar, o disimular cualquier faceta del Servicio que Inkoru posea. El usuario también acepta y se obliga a no utilizar robots, spiders, otros aparatos automatizados, o procesos manuales para controlar o copiar cualquier contenido del Servicio. Inkoru no reclamará derechos sobre la Propiedad Intelectual del Contenido que el usuario suba o proporcione al Servicio. Sin embargo, utilizando el Servicio para enviar contenido, el usuario acepta que terceros puedan ver y compartir este contenido enviado por el usuario.</p>
                                                            <p class="font-weight-bold text-dark font-size-15">Condiciones generales</p>
                                                            <p><i class="bx bx-chevrons-right ml-2"></i> El usuario es completamente responsable del acceso y correcto uso de Inkoru con sujeción a la legalidad vigente, sea nacional o internacional, así como a los principios de buena fe, a la moral, buenas costumbres y orden público. Y específicamente, adquiere el compromiso de observar diligentemente las presentes Condiciones generales de uso.</p>
                                                            <p><i class="bx bx-chevrons-right ml-2"></i> El usuario acepta no revender, duplicar, reproducir o explotar cualquier parte del Servicio sin el expreso consentimiento escrito de Inkoru. El usuario no puede usar el Servicio para almacenar, hospedar, o enviar correo electrónico dañino o no solicitado (spam).</p>
                                                            <p><i class="bx bx-chevrons-right ml-2"></i> El usuario entiende que el Servicio puede utilizarse para la transmisión de su Contenido, y que durante este proceso, su Contenido, incluidos avisos de pago y mensajes personales, puede transferirse sin encriptar a través de Internet. El usuario no puede utilizar el servicio para transmitir ningún virus, gusano, o cualquier tipo de contenido malicioso o dañino.</p>
                                                            <p><i class="bx bx-chevrons-right ml-2"></i> Inkoru no da garantías respecto a su capacidad para usar el Servicio, su satisfacción con el Servicio, que el Servicio esté disponible en todo momento, ininterrumpidamente, y sin errores, la precisión de los cálculos matemáticos llevados a cabo por el Servicio, y la corrección de los errores del Servicio.</p>
                                                            <p><i class="bx bx-chevrons-right ml-2"></i> Ni Inkoru, ni sus socios, ni sus patrocinadores son responsables de cualquier daño directo, indirecto, secundario, consiguiente, especial, ejemplar, punitivo, o de cualquier otro tipo que surja o que esté relacionado de cualquier forma con el uso que el usuario haga del Servicio. el usuario sólo puede solucionar su insatisfacción con el Servicio dejando de utilizarlo y cancelando su cuenta.</p>
                                                            <p><i class="bx bx-chevrons-right ml-2"></i> Si cualquier condición de las aquí descritas queda invalidada o no puede aplicarse, la aplicación de cualquiera de las restantes no debe en ningún caso verse afectada.</p>
                                                            <p>Cualquier pregunta con respecto a las Condiciones de uso debe dirigirse al soporte técnico de Inkoru: info&#64;inkoru.com</p>
                                                            <p>Las Condiciones de uso establecen un entendimiento absoluto entre el usuario e Inkoru con respecto al Servicio y prevalecen ante cualquier acuerdo previo alcanzado entre el usuario e Inkoru. Las Condiciones, y su relación con Inkoru con arreglo a estas Condiciones, se regirá por las leyes de España. el usuario e Inkoru aceptan someterse a la jurisdicción exclusiva de los tribunales de Valencia para resolver cualquier cuestión jurídica en relación con las Condiciones.</p>
                                                            <p class="font-weight-bold text-dark font-size-15">Seguridad y privacidad</p>
                                                            <p>Los datos que los usuarios introducen en Inkoru se encuentran alojados en los servidores de Siteground en la Unión Europea, garantizando así tanto la normativa en alojamiento de datos como los más altos niveles de seguridad y accesibilidad del mercado.</p>
                                                            <p>Inkoru y sus empleados tienen acceso a los datos de las cuentas con el objetivo de poder mejorar el servicio así como de ofrecer un buen servicio de soporte. Todos los empleados y socios de Inkoru tienen firmado un contrato de confidencialidad en cumplimiento con las normativas de privacidad inclusive la GDPR.</p>
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                                                Cerrar
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
  
  
                                            <div class="mt-3">
                                                <button class="btn btn-dark btn-block waves-effect waves-light" [disabled]="formRegister.invalid" (click)="register(formRegister.value)"><i class="bx bx-user-plus align-middle font-size-18"></i> Registrarme</button>
                                            </div>
            
            

                                        </form>
    
                                        <div class="mt-5 text-center">
                                            <p>¿Ya tienes cuenta en Inkoru? <a [routerLink]="[ '/login' ]" class="font-weight-bold text-dark"> Entra</a> </p>
                                        </div>
    
                                    </div>
                                </div>
    
                                <div class="mt-4 mt-md-5 text-center">
                                    <p class="mb-0">© <script>document.write(new Date().getFullYear())</script> Inkoru - Tattoo Management</p>
                                </div>
                            </div>
                            
                            
                        </div>
                    </div>
                </div>
                <!-- end col -->
            </div>
            <!-- end row -->
        </div>
        <!-- end container-fluid -->
    </div>
</div>