import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-confirmacion-modal',
  templateUrl: './confirmacion-modal.component.html',
  styleUrls: ['./confirmacion-modal.component.scss']
})
export class ConfirmacionModalComponent {
  @Input() event: any;
  @Input() newStart: Date;
  @Input() newEnd: Date;

  mensaje: string;
  opcionesNotificacion = {
    avisarArtista: false,
    avisarCliente: false,
    avisarEstudio: false
  };

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    const fecha = moment(this.newStart).format('DD/MM/YYYY');
    const horaInicio = moment(this.newStart).format('HH:mm');
    const horaFin = moment(this.newEnd).format('HH:mm');

    this.mensaje = `La cita se establecerá para el ${fecha}, de ${horaInicio} a ${horaFin}, ¿quieres guardar los cambios? También puedes notificar los cambios a quien decidas:`;
  }

  onConfirmar() {
    this.activeModal.close({
      confirmado: true,
      opcionesNotificacion: this.opcionesNotificacion
    });
  }

  onCancelar() {
    this.activeModal.dismiss('cancel');
  }
}
